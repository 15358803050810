<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
        <!-- end test -->
        <div class="va-card">
          <div class="row">
            <div class="col-md-12">
              <div class="dash_card_body">
                <div class="header_section">
                  <h4 class="menu_header">Items </h4>
                  <div class="">
                    <div class="btn-toolbar" v-if="adminRoles.includes(user.role.id)">
                      <button class="btn btn-purple" style="margin-right:10px;" data-toggle="modal" data-target="#confirm_push"><span> <span v-if="updatingVendors"><span v-html="menuspinner"></span></span>Push Menu To Vendors</span></button>

                      
                      <router-link :to="{ name: 'createitem'}">
                        <button class="btn btn-default custom_btn custon_orange">
                          <i class="fa fa-plus"></i> New Item
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>

                <!-- modal for confirmation -->
                <div class="modal fade" id="confirm_push" role="dialog">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title">Menu Push to third party vendor</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <!-- <h6>Are you sure you want to update menu on all third party vendors?</h6> -->
                        <div class="channel_select">
                          <div class="form-group">
                            <label>Channel: <small>(updates all if no channel is selected)</small></label>
                            <Multiselect v-model="selectedChannels" :disabled="disabled" :value="selectedChannels" mode="tags" placeholder="Select Channel" track-by="name" label="name" :close-on-select="true" :searchable="true" :options="channelOptions">
                              <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                <div class="multiselect-tag is-user">
                                  {{ option.name }}
                                  <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
                                    <span class="multiselect-tag-remove-icon"></span>
                                  </span>
                                </div>
                              </template>
                            </Multiselect>
                          </div>
                        </div>
                        <div class="branch_area">

                          <div class="form-group">
                            <label>Stores: <small>(updates all if no store is selected)</small></label>
                            <Multiselect v-model="branches" :disabled="disabled" :value="branches" mode="tags" placeholder="Select Stores" track-by="name" label="name" :close-on-select="true" :searchable="true" :options="stores">
                              <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                <div class="multiselect-tag is-user">
                                  {{ option.name }}
                                  <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
                                    <span class="multiselect-tag-remove-icon"></span>
                                  </span>
                                </div>
                              </template>
                            </Multiselect>
                          </div>

                        </div>
                      </div> 
                      <div class="modal-footer">
                        <button type="button" id="close_menupush" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-success" @click="pushMenu()" > <span v-if="updatingVendors"><span v-html="menuspinner"></span></span>Push Menu</button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end -->



                <div class="search_section">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group has-search mt-1">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" v-model="itemSearch" @keyup="searchItems()" aria-required="false" autocomplete="on" inputmode="text" name="search_input" placeholder="Search..." class="form-control">
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group has-search">
                        <button class="btn btn-orange search mt-1" @click="searchItems">Search</button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                      
                    <div class="col-md-2 mt-1">
                      <select class="form-control" @change="searchItems()" v-model="typeFilter">
                        <option value="">All</option>
                        <option :value="true">Sold on its own</option>
                        <option :value="false">Not sold on its own</option>
                      </select>
                    </div>
                    <div class="col-md-2 mt-1">
                      <select class="form-control" @change="searchItems()" v-model="itemStatus">
                        <option value="">Select status</option>
                        <option value="1">Published</option>
                        <option value="2">Unpublished</option>
                        <option value="3">Archived</option>
                        <option value="4">Trashed</option>
                        <option value="5">All</option>
                      </select>
                    </div>
                    <div class="col-md-2 mt-1">
                      <Multiselect v-model="categorySearch"   
                        @change="searchItems()"  
                        :close-on-select="true" 
                        :options="catoptions" 
                        :custom-label="name" 
                        :searchable="true" 
                        placeholder="Select Category" 
                        label="name" 
                        :resolve-on-load="false" 
                        track-by="name">
                      </Multiselect>

                      <!-- <select class="form-control" @change="searchItems()" v-model="categorySearch">
                        <option value="">Filter by Category</option>
                        <option v-for="cat in mainCategories" :key="cat.id" :value="cat.id">{{cat.title}}</option>
                      </select> -->
                    </div>
                    <div class="col-md-3 mt-1"><button class="btn btn-dgrey mr-1" @click="clearSearch">
                      <span v-if="searchLoading == 'clear'"><span v-html="searchspinner"></span></span> <span v-else><i class="fa fa-ban"></i> </span> Clear Search
                    </button></div>
                    
                  </div>
                </div>
              </div>
              <hr>
            </div>
            
            <div class="col-lg-12">
              <div class="filters" id="filter_bar" style="display:none">
                      
                <span v-if="adminRoles.includes(user.role.id)">
                  <span class="btn-filter mt-1">
                    <input type="checkbox" id="uncheck" @click="checkItems('uncheck')" indeterminate = true>
                  </span>
                  <button class="btn-filter mr-1 mt-1">
                    {{itemsSelected >1 ? itemsSelected + ' items': itemsSelected + ' item'}}  selected
                  </button>

                  <button class="btn-filter mr-1 mt-1" @click="bulkStatusUpdate('publish',1)">
                    <i class='fa fa-check' style="color:green;"></i>
                    <span v-if="methodSpinner =='publish'"><span v-html="menuspinner"></span> </span> <span v-else> Publish</span>
                  </button>

                  <button class="btn-filter mr-1 mt-1" @click="bulkStatusUpdate('unpublish',2)">
                    <i class="fa fa-close" style="color:red;" aria-hidden="true"></i>
                    <span v-if="methodSpinner =='unpublish'"><span v-html="menuspinner"></span></span> <span v-else> Unpublish</span>
                  </button>

                  <button class="btn-filter mr-1 mt-1" @click="bulkStatusUpdate('trash',4)">
                    <i class="fa fa-trash-o" aria-hidden="true"></i>

                    <span v-if="methodSpinner == 'trash'">
                      <span v-html="menuspinner"></span>
                    </span>
                    <span v-else> Trash</span>
                  </button>
                  <button class="btn-filter mr-1 mt-1" @click="bulkStatusUpdate('archive',3)">
                    <i class="fa fa-archive" aria-hidden="true"></i> <span v-if="methodSpinner =='archive'">
                      <span v-html="menuspinner"></span> </span> <span v-else> Archive</span>
                  </button>
                </span>

              </div>
              <div class="dash_card_body pt-0 ">
                <div class="table_section">
                  <table class="table  table-hover">
                    <thead>
                      <tr>
                        <th scope="col">
                          <input type="checkbox" id="check_all" @click="checkItems('check')">
                        </th>
                        <th scope="col">Name</th>
                        <th v-if="!appConfigs.allow_multiprice" scope="col">Price</th>
                        <th scope="col">Categories</th>
                        <th scope="col"> Menus </th>
                        <th scope="col">POS</th>
                        <th scope="col">Contains</th>
                        <th scope="col">Status</th>
                        <th scope="col">Last Updated</th>
                      </tr>
                    </thead>
                    <tbody v-if="!loading">
                      
                      <tr v-for="(item ,indx) in items" :key="indx" draggable="true"  @drop="onDrop($event,item)" @dragenter.prevent @dragover.prevent @dragstart="startDrag($event,item)" class="drag-el">
                        <td>
                          <input type="checkbox" :id="'items_'+item.menu_item.id" @click="selectITems(item.menu_item.id)">
                        </td>

                        <td>
                          <router-link :to="{ name: 'updateitem', params: { itemid:item.menu_item.id }}">
                            <span class="title_text">{{item.menu_item.item_name}}</span>
                          </router-link>
                        </td>
                        <td v-if="!appConfigs.allow_multiprice">
                          <span v-if="!appConfigs.allow_multiprice">
                            {{formatPrice(item.menu_item.price)}}
                          </span>
                          <span v-else>Price is per channel</span>
                          
                        </td>
                        <td>
                          <div v-if="item.categories !==null">
                            <div @mouseenter="catTooltip = item.id" @mouseleave="catTooltip = 0">
                              <span v-for="(item_category, itemcatindx) in item.categories.slice(0,4)" :key="itemcatindx">
                                {{item_category.title}}
                                <span v-if="itemcatindx + 1 < item.categories.length"> , </span>
                              </span>
                              <span v-if="item.categories.length > 3">...</span>
                            </div>

                            <!-- tool-tip -->
                            <div class="vtooltiptext" v-show="catTooltip == item.id && item.categories.length > 3">
                              <div class="row">
                                <span class="col-md-3" v-for="(i_category, icatindex) in item.categories" :key="icatindex">
                                  {{i_category.title}}
                                </span>
                              </div>

                            </div>
                            <!-- end -->
                          </div>

                          <span v-else>N/A</span>

                        </td>
                        <td>
                          <div v-if="item.menus !==null">
                            <span v-for="(item_menu, itm_menindex) in item.menus" :key="itm_menindex">
                              {{item_menu.title}}
                              <span v-if="itm_menindex + 1 < item.menus.length">,</span>
                            </span>
                          </div>
                          <span v-else>N/A</span>

                        </td>
                        <td>
                          <div v-if="item.menu_item_pos !=undefined && item.menu_item_pos.length > 0 && item.metadata.item_type !=2">
                            <a class="mt-2 toppings" data-toggle="modal" :data-target="'#pos_code_details'+item.menu_item.id" role="dialog" aria-expanded="false" :aria-controls="'pos_code_details'+item.menu_item.id">
                              <!-- <i class="fa fa-eye" aria-hidden="true"></i> -->
                              details
                            </a>

                            <div class="modal fade toppingsModal" :id="'pos_code_details'+item.menu_item.id">
                              <div class="modal-dialog">
                                <div class="modal-content">
                                  <h6>Pos codes</h6>
                                  <ul>
                                    <li v-for="pos in getPosCode(item.menu_item_pos)" :key="pos.pos_code">
                                      <span>
                                        <b> {{pos.name}} : </b> {{ pos.pos_code !='' ? pos.pos_code : 'N/A' }}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <span v-if="item.metadata.item_type == 2">Modifier Override</span>
                            <span v-else>N/A</span>
                          </div>
                          
                        </td>
                        <td>
                          <span v-if="item.mod_groups.length > 0">
                            <div @mouseleave="itemcontains = 0">
                              <span v-for="(modifier, mod_index) in item.mod_groups.slice(0,4)" :key="mod_index">
                                {{modifier.mod_group}}
                                <span v-if="mod_index + 1 < item.mod_groups.length">, </span>
                              </span>
                              <span v-if="item.mod_groups.length > 3"> ..+ {{parseInt(item.mod_groups.length) - 3}}</span>
                            </div>

                            <!--<a class="mt-2 toppings" v-if="item.mod_groups.length > 0" data-toggle="collapse" :href="'#customParams'+item.menu_item.id" role="button" aria-expanded="false" :aria-controls="'customParams'+item.menu_item.id">
                              (details)
                            </a>-->
                            <a class="mt-2 toppings" v-if="item.mod_groups.length > 0" data-toggle="modal" :data-target="'#customParams'+item.menu_item.id" role="dialog" aria-expanded="false" :aria-controls="'customParams'+item.menu_item.id">
                              (details)
                            </a>
                            
                            <div class="modal fade toppingsModal" :id="'customParams'+item.menu_item.id">
                              <div class="modal-dialog">
                                <div class="modal-content">
                                  <ul>
                                    <li v-for="mod_group in item.mod_groups" :key="mod_group.mod_group_id">
                                      <span>
                                        <h6> {{mod_group.mod_group}}</h6>
                                      </span>
                                      <div v-if="mod_group.items.length > 0">
                                        <ul>
                                          <li v-for="(mod_item,itemindx) in mod_group.items" :key="mod_item.id">
                                            <!-- {{ getModItemPoscode(item.menu_item.id,mod_group.mod_group_id,mod_item.id, item.mod_menu_item_pos) }} -->
                                            {{itemindx + 1}}. <span>{{mod_item.name}} - {{mod_item.pos_id}}</span> <br></li>
                                        </ul>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <!-- end -->
                          </span>
                          <span v-else>N/A</span>
                        </td>
                        <td>
                          <div class="btn-group">
                            <button type="button" class="btn btn-sm custom_btn btn-default">
                              <span v-if="item.menu_item.status == 1">
                                <i class='fa fa-check' style="color:green;"></i>
                              </span>
                              <span v-else-if="item.menu_item.status == 2">
                                <i class="fa fa-close" style="color:red;" aria-hidden="true"></i>
                              </span>
                              <span v-else-if="item.menu_item.status == 3">
                                <i class="fa fa-archive" aria-hidden="true"></i>
                              </span>
                              <span v-else>
                                <i class="fa fa-trash-o" aria-hidden="true"></i>
                              </span>
                            </button>
                            <button v-if="adminRoles.includes(user.role.id)" type="button" class="btn btn-sm custom_btn btn-default dropdown-toggle" data-toggle="dropdown" :id="'dropdownMenuButton'+item.menu_item.id">
                              <span class="caret"></span>
                              <span class="sr-only">Toggle Dropdown</span>
                            </button>

                            <div class="dropdown-menu" :aria-labelledby="'dropdownMenuButton'+item.menu_item.id">
                              <a class="dropdown-item" @click="updateStatsPerItem(item.menu_item.id,1)" href="#">Publish</a>
                              <a class="dropdown-item" @click="updateStatsPerItem(item.menu_item.id,2)" href="#">Unpublish</a>
                              <a class="dropdown-item" @click="updateStatsPerItem(item.menu_item.id,3)" href="#">Archive</a>
                              <a class="dropdown-item" @click="updateStatsPerItem(item.menu_item.id,4)" href="#">trash</a>
                            </div>
                          </div>

                        </td>
                        <td>
                          <span v-if="item.menu_item.updated_at !==null">{{formatDate(item.menu_item.updated_at,1)}}</span>
                          <span v-else>{{formatDate(item.menu_item.created_at,1)}}</span>
                        </td>
                      </tr>
                      

                      

                    </tbody>
                    <tfoot v-if="!loading">
                      <tr>
                        <td colspan="9">
                          <div id="pagination" >
                            <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />
                            <ul class="showItems">
                              <li>Show Items:
                                <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                  <option :value="10">10</option>
                                  <option :value="20">20</option>
                                  <option :value="30">30</option>
                                  <option :value="40">40</option>
                                  <option :value="50">50</option>
                                </select>
                              </li>

                            </ul>
                          </div>

                        </td>
                      </tr>
                    </tfoot>
                  </table>

                </div>
              </div>
              <div v-if="loading" class="loading-cover">
                <div class="loader"> </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import Pagination from '@/pages/pagination/pagination.vue';
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
    components: {
        Pagination,
        Multiselect
    },
    data() {
        return {
            categoryOptions: [],
            itemsSelected: 0,
            items: [],
            loading: false,
            page: 1,
            totalPages: 0,
            itemSearch: '',
            categorySearch: this.$store.state.itemCategorySearch,
            totalRecords: 0,
            recordsPerPage: this.$store.state.itemsRecordPerPage,
            itemcontains: 0,
            catTooltip: 0,
            typeFilter: this.$store.state.itemTypeFilter !== '' ? this.$store.state.itemTypeFilter : '',
            itemStatus: this.$store.state.itemStatus,
            searchItemsArr: [],
            itemIds: [],
            mainCategories: [],
            debounce: null,
            updatingVendors: false,
            disabled: false,
            searchLoading: '',
            methodSpinner: null,
            menuspinner: "<i class='fa fa-spinner fa-spin '></i>",
            posConfigs: [],
            channels:[],
            selectedChannels:[],
            channelOptions:[],
            branches:[],
            stores:[],
            catoptions:[]
        }
    },
    mounted: async function () {
        this.getItems();
        this.getCategories();
        this.getPosConfigs();
        this.getThirdParties();
        this.getBranches();
        this.itemIds = [];
        // disable input if use is not admin or superadmin

    },
    methods: {
      async getBranches() {
          const config = {
              headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
          };
          const url = this.baseUrl + "/branch?merchant_id=" + this.merchantID;
          this.axios.get(url, config).then((response) => {
              if (response.status === 200) {
                  if (response.data.data !== null) {
                      const stores = response.data.data;
                      for (let i = 0; i < stores.length; i++) {
                          this.stores.push({
                              "value": stores[i].id,
                              "name": stores[i].branch_name
                          })
                      }
                  }
              }
          }).catch((error) => {
              if (error.response.status === 401) {
                  this.$router.push({
                      name: 'login'
                  });
              }
          })

      },
        async getPosConfigs() {
              const config = {
                  headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
              };
              const url = `${this.dashBaseUrl}/merchant-pos-config/${this.merchantID}`;
              this.axios.get(url, config).then((response) => {
                  if (response.status === 200) {
                      this.posConfigs = response.data.data
                  }
              }).catch((error) => {
                  if (error.response.status === 401) {
                      this.$router.push({
                          name: 'login'
                      });
                  }
              });
              // 
        },
        async getThirdParties() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.baseUrl + "/third-party-setting?merchant_id=" + this.merchantID;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    const channels = response.data.data
                    for (let c = 0; c < channels.length; c++) {
                        this.channelOptions.push({
                            "value": channels[c].channel_name,
                            "name": channels[c].channel_name
                        })
                    }
                }
            });
        },
        getPosCode(itemposcodes){ 
          const poscodes = [];
          for(let x=0;x< this.posConfigs.length; x++){
            for(let i =0; i < itemposcodes.length; i++){
              if(this.posConfigs[x].id == itemposcodes[i].pos_systems){
                poscodes.push({
                  'name': this.posConfigs[x].name,
                  'pos_code': itemposcodes[i].pos_id
                })
              }
            }
          }
          return poscodes
        },
        getModItemPoscode(parentItemId,ModId,itemid, modPoscode){
          const poscodes = []
          for(let x=0;x< this.posConfigs.length; x++){
            for(let i=0; i<modPoscode.length; i++){
              if(
                this.posConfigs[x].id == modPoscode[i].pos_systems && 
                modPoscode[i].parent_item_id == parentItemId && 
                modPoscode[i].item_id == itemid &&  
                modPoscode[i].mod_group_id == ModId 
              ){
                poscodes.push({
                  'name': this.posConfigs[x].name,
                  'pos_code':modPoscode[i].pos_id
                })
                // 
              }
            }
          }
          return poscodes;
          // 
        },
        startDrag(event, item) {     
          event.dataTransfer.dropEffect = 'move'      
          event.dataTransfer.effectAllowed = 'move' 
          event.dataTransfer.setData('itemId',item.menu_item.id);
          
          // evt.dataTransfer.setData('itemID', item.id)    
        },
        async onDrop(event,item){
          const itemD = event.dataTransfer.getData('itemId')
          if(this.categorySearch !=''){
            try{
              const config = {
                  headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
              };
              const url = `${this.baseUrl}/menu_item/position`;
              const payload = {
                'merchant_id': parseInt(this.merchantID),
                'parent_id': this.categorySearch,
                'operation_type': 0,
                'update':{
                  'id':itemD,
                  'position': item.menu_item.position
                }
              }

              const response = await this.axios.patch(url,payload, config);
              if(response.status ==200){
                this.getItems();
              }

            }catch(error){
              console.log('error')
            }
          }
        },
        searchItems() {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
              const serchparams = {
                  'type_filter': this.typeFilter,
                  'item_search': this.itemStatus,
                  'category_search': this.categorySearch
              };
              this.$store.commit('updateSearch',serchparams);
              this.getItems();
            }, 1500)
        },
        clearSearch() {
            this.searchLoading = 'clear'
            this.itemSearch = '';
            this.typeFilter = true;
            this.itemStatus = '';
            this.categorySearch = ''
            const serchparams = {
                'type_filter': this.typeFilter,
                'item_search': this.itemStatus,
                'category_search': this.categorySearch
            };
            this.$store.commit('updateSearch', serchparams);
            this.getItems();
        },
        checkItems(type){
          document.querySelector('#uncheck').indeterminate = true;
          if(type === 'check'){
            if(document.getElementById('check_all').checked){
               document.getElementById('filter_bar').style.display='block'
               this.itemsSelected = 0;
                for(const item of Object.values(this.items)){
                  this.itemsSelected +=1;
                  document.getElementById('items_' + item.menu_item.id).checked = true
                  if (this.itemIds.includes(item.menu_item.id) === false) {
                        this.itemIds.push(item.menu_item.id);
                  }
                }
            }else{
              document.getElementById('filter_bar').style.display='none'
              for(const item of Object.values(this.items)){
                  document.getElementById('items_' + item.menu_item.id).checked = false
                }
                this.itemIds = []
            }
            
          }else{
            document.getElementById('filter_bar').style.display='none'
            document.getElementById('check_all').checked =  false
            for(const item of Object.values(this.items)){
              document.getElementById('items_' + item.menu_item.id).checked = false
            }
            this.itemIds = []
          }

        },
        selectITems(itemId) {
            if (document.getElementById('items_' + itemId).checked) {
                if (this.itemIds.includes(itemId) === false) {
                    this.itemIds.push(itemId);
                }
                document.getElementById('filter_bar').style.display='block'
            } else {
                if (this.itemIds.indexOf(itemId) !== -1) {
                    this.itemIds = this.itemIds.filter((x) => x !== itemId);
                }
                // checking if there is any checked box
                for(const item of Object.values(this.items)){
                  if(item.menu_item.id != itemId && document.getElementById('items_' + item.menu_item.id).checked){
                    document.getElementById('filter_bar').style.display='block'
                    break; 
                  }else{
                    document.getElementById('filter_bar').style.display='none'
                  }
                }
                // 
            }
            // increasing count on item selected
            this.itemsSelected = 0;
            for(const item of Object.values(this.items)){
              if(document.getElementById('items_' + item.menu_item.id).checked){
                this.itemsSelected +=1;
              }
            }
        },
        async getItems() {

            const controller = new AbortController();
            const signal = controller.signal

            this.loading = true;
            this.items = [];
            let url = `${this.baseUrl}/menu_item`;
            url = new URL(url);
            url.searchParams.set('page', this.page);
            url.searchParams.set('size', this.recordsPerPage);
            url.searchParams.set('item_name', this.itemSearch);
            url.searchParams.set('standalone', this.typeFilter);
            url.searchParams.set('status', this.itemStatus);
            url.searchParams.set('merchant_id', this.merchantID);
            if(this.categorySearch){
              url.searchParams.set('category', this.categorySearch);
            }
            if (this.user.role.id === 3) {
                url.searchParams.set('user_id', this.user.id)
            }
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            this.axios.get(url, config,{signal}).then((response) => {
                if (response.status === 200) {
                    this.items = response.data.data;
                    this.totalPages = Math.ceil(response.data.total / this.recordsPerPage)
                    this.totalRecords = response.data.total_count
                }
            }).catch((error) => {
              
                if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            }).finally(() => {
                this.loading = false;
                this.searchLoading = '';
            })
            controller.abort()
        },
        onPageChange(page) {
            this.itemIds = []
            document.getElementById('check_all').checked = false;
            document.getElementById('filter_bar').style.display='none';
            this.page = page
            this.getItems();
        },
        onChangeRecordsPerPage() {
            this.itemIds = []
            const payload = {
              "type":"item",
              "size":this.recordsPerPage
            }
            this.$store.commit("setPageLimit",payload)
            // setPageLimit
            document.getElementById('check_all').checked = false;
            document.getElementById('filter_bar').style.display='none';
            this.getItems();
        },
        bulkStatusUpdate(method, status) {
            this.updateStatus(this.itemIds, method, status);
        },
        updateStatsPerItem(itemId, status) {
            const itemIdsArr = [];
            if (itemIdsArr.includes(itemId) === false) {
                itemIdsArr.push(itemId)
            }
            this.updateStatus(itemIdsArr, '', status)
        },
        updateStatus(ItemIds, method, status) {
            if (ItemIds.length > 0) {
                this.methodSpinner = method;
                const payload = {
                    "merchant_id": parseInt(this.merchantID),
                    "ids": ItemIds,
                    "status": status
                };
                const url = this.baseUrl + "/menu_item/status";
                this.axios.patch(url, payload,this.apiRequestConfig()).then((response) => {
                    this.itemIds = [];
                    if (response.status === 200) {
                        toast.success('updated successfully')
                        this.methodSpinner = null;
                        this.getItems();
                        // 
                    }
                }).catch((error) => {
                    this.methodSpinner = null;

                    if (error.response.status === 401) {
                        this.$router.push({
                            name: 'login'
                        });
                    }
                });
            }
        },
        pushMenu() {
            this.updatingVendors = true;
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.baseUrl + "/menu_push";
            // const payload = {
            //     "merchant_id": this.merchantID,
            //     "user_id": this.user.id
            // };

            const payload = {}
            payload["merchant_id"] = parseInt(this.merchantID);
            payload["user_id"] = this.user.id
            if(this.branches.length > 0){
              payload["branch_ids"] = this.branches
            }
            if(this.selectedChannels.length > 0){
              payload["channels"] = this.selectedChannels
            }
            this.axios.post(url, payload, config).then((response) => {
                if (response.status === 200) {
                    this.updatingVendors = false;
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            }).finally(() => {
              document.getElementById('close_menupush').click();
              this.updatingVendors = false;
            })
        },

        getCategories() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.baseUrl + "//menu_category?merchant_id=" + this.merchantID;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    this.mainCategories = response.data.data;
                    for(let i=0; i < this.mainCategories.length ; i++){
                      this.catoptions.push({
                            "value": this.mainCategories[i].id,
                            "name": this.mainCategories[i].title
                        })
                    }
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            })
        }
    },
    mixins: [ReusableFunctionMixins, ReusableDataMixins]
}
</script>